<template>
    <div v-html="content" class="w-full p-16 pt-0"></div>
  </template>
  
  <script>
  import { onMounted, inject, ref } from 'vue';
  
  export default {
    name: 'Terms',
  
    setup() {
      const axios = inject("axios");
      const content = ref("");
  
      onMounted(() => {
        axios.api.get("/getContent?type=terms-and-conditions")
          .then((res) => {
            console.log(res.data.data.content);
            content.value = res.data.data.content;
          })
          .catch((error) => {
            console.error('Error fetching content:', error);
          });
      });
  
      return {
        content
      };
    }
  };
  </script>